<!-- @Author: Yu_Bo -->
<template>
	<div class='point_succ zc_dialog_tip'>
		<el-dialog custom-class="zc_dialog_tip" :style="[allStyle]" :visible.sync="dialogVisible" :width="dialogWidth"
		  :close-on-click-modal='false' append-to-body>
		  <div class="title" slot="title">
		    <i v-if="iconShow" class="el-icon-success" :style="[iconStyle]"></i>
		    <div class="name">{{ dialogTitle }}</div>
		  </div>
		  <slot></slot>
		  <span slot="footer" class="dialog-footer">
		    <el-button v-if="cancelShow" class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
		    <el-button v-if="affirmShow" class="btnBgColor_blue" size="small" type="primary" :loading='loading' @click="affirmBtn">我知道了</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      type: {//类型
        type: String,
        defaul: ''
      },
      dialogTitle: {//标题
        type: String,
        defaul: '提示'
      },
      dialogWidth: {//宽度
        type: String,
        defaul: '30%'
      },
      iconShow: {//图标是否显示
        type: Boolean,
        defaul: false
      },
      iconColor: {//图标颜色
        type: String,
        defaul: '#333'
      },
      borderRadius: {//圆角
        type: String,
        defaul: '2px'
      },
      cancelShow: {//取消按钮是否显示
        type: Boolean,
        defaul: true
      },
      affirmShow: {//确认按钮是否显示
        type: Boolean,
        defaul: true
      }
    },
		data() {
			return{
        dialogVisible: false,
        //
        id:'',
        loading:false
			}
		},
		computed: {
      allStyle() {
        return {
          '--borderRadius': this.borderRadius
        }
      },
      iconStyle() {
        return {
          color: this.iconColor
        }
      },
    },
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        if(val){
          this.id=val
        }
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },
      // 确定
      affirmBtn() {
        if(this.type=='model'){
          this.cancelBtn()
          // this.$emit('refreshBtn')
          // this.notifyRecharge(1)
        }else if(this.type=='sound'){
          this.notifyRecharge(2)
        } else{
          this.dialogVisible = false
        }
      },
      // 通知充值
      notifyRecharge(type){
        var that = this
        that.loading=true
        if(type==1){
          var params = {
            id:that.id
          }
          that.$trainApi.callRecharge(params).then(res => {
            that.loading=false
            that.dialogVisible = false
            if (res.code == 1000) {
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }else if(type==2){
          var params = {
            id:that.id
          }
          that.$trainApi.ttsListCallRecharge(params).then(res => {
            that.loading=false
            that.dialogVisible = false
            if (res.code == 1000) {
              that.$succMsg(res.message)
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
  ::v-deep .el-dialog {
    border-radius: var(--borderRadius);
  }
  .point_succ,
  .zc_dialog_tip {

    .title {
      display: flex;
      align-items: center;

      .el-icon-success {
        font-size: 24px;
      }

      .name {
        padding-left: 10px;
        padding-bottom: 3px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
</style>
